@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

*, ::after, ::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

.h1, h1 {
  font-size: 2.5rem; }

.h2, h2 {
  font-size: 2rem; }

.h3, h3 {
  font-size: 1.75rem; }

.h4, h4 {
  font-size: 1.5rem; }

.h5, h5 {
  font-size: 1.25rem; }

.h6, h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.small, small {
  font-size: 80%;
  font-weight: 400; }

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: .5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: .5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code, kbd, pre, samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem; }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }

.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.table tbody + tbody {
  border-top: 2px solid #dee2e6; }

.table .table {
  background-color: #fff; }

.table-sm td, .table-sm th {
  padding: .3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6; }

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff; }

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf; }

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb; }

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5; }

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1; }

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7; }

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6; }

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe; }

.table-active, .table-active > td, .table-active > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e; }

.table-dark.table-bordered {
  border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-xl > .table-bordered {
    border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.table-responsive > .table-bordered {
  border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file, .form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: .375rem;
  padding-bottom: .375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .input-group-append > .form-control-plaintext.btn, .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .input-group-append > .form-control-plaintext.btn, .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: .25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
  border-color: #28a745; }

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745; }

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745; }

.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a; }

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745; }

.custom-file-input.is-valid ~ .custom-file-label::before, .was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit; }

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block; }

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: #dc3545; }

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545; }

.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9; }

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545; }

.custom-file-input.is-invalid ~ .custom-file-label::before, .was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit; }

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .input-group {
    width: auto; }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.btn:focus, .btn:hover {
  text-decoration: none; }

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn.disabled, .btn:disabled {
  opacity: .65; }

.btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  background-image: none; }

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf; }

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62; }

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b; }

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent; }

.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none; }

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d; }

.btn-group-lg > .btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.btn-group-sm > .btn, .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: .5rem; }

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity .15s linear; }

.fade.show {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.show {
  display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

.dropdown, .dropup {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-bottom: .3em solid transparent;
  border-left: .3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group, .btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover {
  z-index: 1; }

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1; }

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem; }

.dropdown-toggle-split::after {
  margin-left: 0; }

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem; }

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%; }

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

.btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group > .custom-file:focus, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3; }

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control {
  margin-left: -1px; }

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-append, .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.input-group-append .btn, .input-group-prepend .btn {
  position: relative;
  z-index: 2; }

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.input-group-text input[type=checkbox], .input-group-text input[type=radio] {
  margin-top: 0; }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6; }

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #80bdff; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0; }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: .5rem 1rem; }

.nav-link:focus, .nav-link:hover {
  text-decoration: none; }

.nav-link.disabled {
  color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: .25rem; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem; }

.navbar > .container, .navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static;
  float: none; }

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem; }

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0; }

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-text a {
  color: #fff; }

.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: .75rem; }

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px; } }

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:first-child .card-header, .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0; }
  .card-group > .card:first-child .card-footer, .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0; }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:last-child .card-header, .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0; }
  .card-group > .card:last-child .card-footer, .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0; }
  .card-group > .card:only-child {
    border-radius: .25rem; }
  .card-group > .card:only-child .card-header, .card-group > .card:only-child .card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .card-group > .card:only-child .card-footer, .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0; } }

.card-columns .card {
  margin-bottom: .75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem; }
  .card-columns .card {
    display: inline-block;
    width: 100%; } }

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  padding-left: .5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem; }

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem; }

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }

.badge-light[href]:focus, .badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.alert-primary hr {
  border-top-color: #9fcdff; }

.alert-primary .alert-link {
  color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }

.alert-secondary hr {
  border-top-color: #c8cbcf; }

.alert-secondary .alert-link {
  color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-success hr {
  border-top-color: #b1dfbb; }

.alert-success .alert-link {
  color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.alert-info hr {
  border-top-color: #abdde5; }

.alert-info .alert-link {
  color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-warning hr {
  border-top-color: #ffe8a1; }

.alert-warning .alert-link {
  color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-danger hr {
  border-top-color: #f1b0b7; }

.alert-danger .alert-link {
  color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light hr {
  border-top-color: #ececf6; }

.alert-light .alert-link {
  color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

.alert-dark hr {
  border-top-color: #b9bbbe; }

.alert-dark .alert-link {
  color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width .6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.list-group-item:focus, .list-group-item:hover {
  z-index: 1;
  text-decoration: none; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75; }

.close:not(:disabled):not(.disabled) {
  cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%); }

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2)); }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; }

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }

.modal-footer > :not(:first-child) {
  margin-left: .25rem; }

.modal-footer > :not(:last-child) {
  margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: .9; }

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem; }

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
  bottom: 0; }

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000; }

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000; }

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
  top: 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000; }

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem; }

.popover .arrow::after, .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-auto[x-placement^=top], .bs-popover-top {
  margin-bottom: .5rem; }

.bs-popover-auto[x-placement^=top] .arrow, .bs-popover-top .arrow {
  bottom: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::after, .bs-popover-top .arrow::before {
  border-width: .5rem .5rem 0; }

.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff; }

.bs-popover-auto[x-placement^=right], .bs-popover-right {
  margin-left: .5rem; }

.bs-popover-auto[x-placement^=right] .arrow, .bs-popover-right .arrow {
  left: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-right .arrow::before {
  border-width: .5rem .5rem .5rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff; }

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: .5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow, .bs-popover-bottom .arrow {
  top: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::after, .bs-popover-bottom .arrow::before {
  border-width: 0 .5rem .5rem .5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff; }

.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-auto[x-placement^=left], .bs-popover-left {
  margin-right: .5rem; }

.bs-popover-auto[x-placement^=left] .arrow, .bs-popover-left .arrow {
  right: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::after, .bs-popover-left .arrow::before {
  border-width: .5rem 0 .5rem .5rem; }

.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff; }

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: .5rem .75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease,-webkit-transform .6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px; }

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block; }

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.active.carousel-item-right, .carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-right, .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.active.carousel-item-left, .carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-left, .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5; }

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5); }

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators .active {
  background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0, .py-0 {
  padding-top: 0 !important; }

.pr-0, .px-0 {
  padding-right: 0 !important; }

.pb-0, .py-0 {
  padding-bottom: 0 !important; }

.pl-0, .px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1, .py-1 {
  padding-top: 0.25rem !important; }

.pr-1, .px-1 {
  padding-right: 0.25rem !important; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1, .px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2, .py-2 {
  padding-top: 0.5rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2, .px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3, .py-3 {
  padding-top: 1rem !important; }

.pr-3, .px-3 {
  padding-right: 1rem !important; }

.pb-3, .py-3 {
  padding-bottom: 1rem !important; }

.pl-3, .px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4, .py-4 {
  padding-top: 1.5rem !important; }

.pr-4, .px-4 {
  padding-right: 1.5rem !important; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4, .px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5, .py-5 {
  padding-top: 3rem !important; }

.pr-5, .px-5 {
  padding-right: 3rem !important; }

.pb-5, .py-5 {
  padding-bottom: 3rem !important; }

.pl-5, .px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto, .my-auto {
  margin-top: auto !important; }

.mr-auto, .mx-auto {
  margin-right: auto !important; }

.mb-auto, .my-auto {
  margin-bottom: auto !important; }

.ml-auto, .mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *, ::after, ::before {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  blockquote, pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td, .table th {
    background-color: #fff !important; }
  .table-bordered td, .table-bordered th {
    border: 1px solid #ddd !important; } }

/*# sourceMappingURL=bootstrap.min.css.map */
[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate(0); }

[data-aos=fade-up] {
  transform: translateY(100px); }

[data-aos=fade-down] {
  transform: translateY(-100px); }

[data-aos=fade-right] {
  transform: translate(-100px); }

[data-aos=fade-left] {
  transform: translate(100px); }

[data-aos=fade-up-right] {
  transform: translate(-100px, 100px); }

[data-aos=fade-up-left] {
  transform: translate(100px, 100px); }

[data-aos=fade-down-right] {
  transform: translate(-100px, -100px); }

[data-aos=fade-down-left] {
  transform: translate(100px, -100px); }

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate(0) scale(1); }

[data-aos=zoom-in] {
  transform: scale(0.6); }

[data-aos=zoom-in-up] {
  transform: translateY(100px) scale(0.6); }

[data-aos=zoom-in-down] {
  transform: translateY(-100px) scale(0.6); }

[data-aos=zoom-in-right] {
  transform: translate(-100px) scale(0.6); }

[data-aos=zoom-in-left] {
  transform: translate(100px) scale(0.6); }

[data-aos=zoom-out] {
  transform: scale(1.2); }

[data-aos=zoom-out-up] {
  transform: translateY(100px) scale(1.2); }

[data-aos=zoom-out-down] {
  transform: translateY(-100px) scale(1.2); }

[data-aos=zoom-out-right] {
  transform: translate(-100px) scale(1.2); }

[data-aos=zoom-out-left] {
  transform: translate(100px) scale(1.2); }

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate(0); }

[data-aos=slide-up] {
  transform: translateY(100%); }

[data-aos=slide-down] {
  transform: translateY(-100%); }

[data-aos=slide-right] {
  transform: translateX(-100%); }

[data-aos=slide-left] {
  transform: translateX(100%); }

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0); }

/*# sourceMappingURL=aos.css.map*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

body {
  margin: 0;
  overflow: hidden;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  transition: opacity 400ms; }

body, .onepage-wrapper, html {
  display: block;
  position: static;
  padding: 0;
  width: 100%;
  height: 100%; }

.onepage-wrapper {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  padding: 0;
  -webkit-transform-style: preserve-3d; }

.onepage-wrapper .section {
  width: 100%;
  height: 100%; }

.onepage-pagination {
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 5;
  list-style: none;
  margin: 0;
  padding: 0; }

.onepage-pagination li {
  padding: 0;
  text-align: center; }

.onepage-pagination li a {
  padding: 10px;
  width: 4px;
  height: 4px;
  display: block; }

.onepage-pagination li a:before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.onepage-pagination li a.active:before {
  width: 10px;
  height: 10px;
  background: none;
  border: 1px solid black;
  margin-top: -4px;
  left: 8px; }

.disabled-onepage-scroll, .disabled-onepage-scroll .wrapper {
  overflow: auto; }

.disabled-onepage-scroll .onepage-wrapper .section {
  position: relative !important;
  top: auto !important;
  left: auto !important; }

.disabled-onepage-scroll .onepage-wrapper {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  transform: none !important;
  -ms-transform: none !important;
  min-height: 100%; }

.disabled-onepage-scroll .onepage-pagination {
  display: none; }

body.disabled-onepage-scroll, .disabled-onepage-scroll .onepage-wrapper, html {
  position: inherit; }

@font-face {
  font-family: "MuseoSansCyrl-100";
  src: url("../fonts/MuseoSansCyrl-100.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-100Italic";
  src: url("../fonts/MuseoSansCyrl-100Italic.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-300";
  src: url("../fonts/MuseoSansCyrl-300.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-300Italic";
  src: url("../fonts/MuseoSansCyrl-300Italic.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-500";
  src: url("../fonts/MuseoSansCyrl-500.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-500Italic";
  src: url("../fonts/MuseoSansCyrl-500Italic.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-700";
  src: url("../fonts/MuseoSansCyrl-700.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-700Italic";
  src: url("../fonts/MuseoSansCyrl-700Italic.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-900";
  src: url("../fonts/MuseoSansCyrl-900.otf"); }

@font-face {
  font-family: "MuseoSansCyrl-900Italic";
  src: url("../fonts/MuseoSansCyrl-900Italic.otf"); }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?4snck8");
  src: url("../fonts/icomoon.eot?4snck8#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?4snck8") format("truetype"), url("../fonts/icomoon.woff?4snck8") format("woff"), url("../fonts/icomoon.svg?4snck8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lighthaus";
  src: url("../fonts/Lighthaus.otf"); }

body,
html {
  min-width: 320px; }
  body *,
  html * {
    font-family: "MuseoSansCyrl-100", sans-serif;
    outline: none; }
  @media screen and (max-width: 992px) {
    body,
    html {
      overflow: auto !important;
      height: auto;
      position: relative; } }
  body.no-one-page-effect,
  html.no-one-page-effect {
    overflow: auto;
    position: relative;
    height: auto; }
    body.no-one-page-effect .header,
    body.no-one-page-effect .footer,
    html.no-one-page-effect .header,
    html.no-one-page-effect .footer {
      position: relative; }
    body.no-one-page-effect .header,
    html.no-one-page-effect .header {
      position: absolute; }
    body.no-one-page-effect .header__inner,
    html.no-one-page-effect .header__inner {
      position: absolute; }
    body.no-one-page-effect .footer__inner,
    html.no-one-page-effect .footer__inner {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
    body.no-one-page-effect section:first-child,
    html.no-one-page-effect section:first-child {
      padding-top: 100px; }
    body.no-one-page-effect section:last-child,
    html.no-one-page-effect section:last-child {
      padding-bottom: 100px; }

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-facebook:before {
  content: "\e900"; }

.icon-g-plus:before {
  content: "\e901"; }

.icon-insta:before {
  content: "\e902"; }

.icon-mail:before {
  content: "\e903"; }

.icon-phone:before {
  content: "\e904"; }

.special-font {
  font-family: "Lighthaus" !important; }
  .special-font * {
    font-family: "Lighthaus" !important; }

@media (min-width: 1470px) {
  .container {
    max-width: 1440px; } }

.screen {
  position: relative;
  padding: 30px 0;
  background-color: #f3f3f3;
  min-height: 100vh; }
  .screen * {
    color: #1d2126; }
  .screen .current-section-bg {
    background-color: #f3f3f3; }
  .screen--dark {
    background-color: #0f1011;
    background-image: linear-gradient(-31deg, #0f1011 0%, #323639 100%); }
    .screen--dark * {
      color: #ffffff; }
    .screen--dark .current-section-bg {
      background-color: #0f1011; }
    .screen--dark .take-service-btn-transparent {
      color: #ffffff; }
  .screen--white {
    background-color: #fff; }
    .screen--white .current-section-bg {
      background-color: #fff; }
  @media screen and (max-width: 991px) {
    .screen {
      min-height: 30vh;
      height: auto !important; } }

.first-screen {
  background-image: url(../img/first-screen-bg.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
  position: relative; }
  .first-screen h2 {
    font-family: "MuseoSansCyrl-100";
    font-size: 50px;
    display: inline-block;
    position: relative;
    z-index: 200;
    text-transform: uppercase;
    transform: translate(75%, 0);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 1366px) {
      .first-screen h2 {
        font-size: 40px; } }
    @media screen and (max-width: 992px) {
      .first-screen h2 {
        font-size: 24px; } }
    @media screen and (max-width: 768px) {
      .first-screen h2 {
        font-size: 21px; } }
    @media screen and (max-width: 480px) {
      .first-screen h2 {
        font-size: 14px; } }
    @media screen and (max-width: 320px) {
      .first-screen h2 {
        font-size: 9px; } }
  .first-screen .bg-lines-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../img/first-screen-bg-lines.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }

.special-font {
  padding-left: 60px; }
  @media screen and (max-width: 992px) {
    .special-font {
      padding-left: 15px; } }

@media screen and (max-width: 991px) {
  .screens-wraper {
    height: auto !important; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 116px;
  height: auto;
  z-index: 9999;
  padding-top: 10px;
  opacity: 1;
  transition-delay: 0.3s;
  transition: opacity 0.3s ease-in-out; }
  .header .burger-btn {
    display: block;
    width: 30px;
    height: 17px;
    position: relative;
    transform-style: preserve-3d; }
    .header .burger-btn span {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: #1d2126;
      transition: all 0.3s ease-in-out;
      transform: translate(0, -1px);
      opacity: 1;
      transform-style: preserve-3d; }
    .header .burger-btn:before, .header .burger-btn:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #1d2126;
      position: absolute;
      left: 0;
      transform-origin: 50% 50%;
      transition: all 0.3s ease-in-out;
      transform-style: preserve-3d; }
    .header .burger-btn:before {
      top: 0; }
    .header .burger-btn:after {
      top: calc(100% - 1px); }
    .header .burger-btn.clicked span, .header .burger-btn.clicked:before, .header .burger-btn.clicked:after {
      background-color: #ffffff; }
      @media screen and (max-width: 991px) {
        .header .burger-btn.clicked span, .header .burger-btn.clicked:before, .header .burger-btn.clicked:after {
          background-color: #1d2126; } }
    .header .burger-btn.clicked span {
      opacity: 0; }
    .header .burger-btn.clicked:before, .header .burger-btn.clicked:after {
      top: 50%; }
    .header .burger-btn.clicked:before {
      transform: rotate(135deg) translate(0, -50%); }
    .header .burger-btn.clicked:after {
      transform: rotate(225deg) translate(0, -50%); }
    .header .burger-btn__on-first-screen span, .header .burger-btn__on-first-screen:before, .header .burger-btn__on-first-screen:after {
      background-color: #1d2126 !important; }
    .header .burger-btn__on-first-screen.clicked span, .header .burger-btn__on-first-screen.clicked:before, .header .burger-btn__on-first-screen.clicked:after {
      background-color: #ffffff !important; }
      @media screen and (max-width: 991px) {
        .header .burger-btn__on-first-screen.clicked span, .header .burger-btn__on-first-screen.clicked:before, .header .burger-btn__on-first-screen.clicked:after {
          background-color: #1d2126 !important; } }
  .header .img-dark {
    display: none; }
  .header.on-dark-screen *, .header__team * {
    color: #ffffff; }
    @media screen and (max-width: 991px) {
      .header.on-dark-screen *, .header__team * {
        color: #1d2126; } }
  .header.on-dark-screen .burger-btn span, .header.on-dark-screen .burger-btn:before, .header.on-dark-screen .burger-btn:after, .header__team .burger-btn span, .header__team .burger-btn:before, .header__team .burger-btn:after {
    background-color: #ffffff; }
    @media screen and (max-width: 991px) {
      .header.on-dark-screen .burger-btn span, .header.on-dark-screen .burger-btn:before, .header.on-dark-screen .burger-btn:after, .header__team .burger-btn span, .header__team .burger-btn:before, .header__team .burger-btn:after {
        background-color: #1d2126; } }
  .header.on-scroll {
    opacity: 0; }
  .header .logo img {
    width: 100%;
    max-width: 130px;
    height: auto; }
  .header__inner {
    background-color: transparent; }
  @media screen and (max-width: 991px) {
    .header {
      position: relative;
      padding: 15px 0;
      min-height: auto; }
      .header__inner {
        background-color: transparent; }
        .header__inner.header--dark .burger-btn span, .header__inner.header--dark .burger-btn:before, .header__inner.header--dark .burger-btn:after {
          background-color: #ffffff; } }

.screen.screen__inner-first {
  padding-top: 150px !important; }

.screen.screen__inner-last {
  padding-bottom: 100px !important; }

.contacts-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0; }
  .contacts-list li {
    display: inline-block;
    margin-right: 39px; }
    .contacts-list li:last-child {
      margin-right: 0; }
    .contacts-list li a {
      font-family: "MuseoSansCyrl-100", sans-serif;
      font-size: 0.8rem;
      color: currentColor;
      text-decoration: none; }
      .contacts-list li a:hover {
        text-decoration: underline; }
      .contacts-list li a i {
        margin-right: 5px; }
    @media screen and (min-width: 0px) and (max-width: 991px) {
      .contacts-list li {
        margin-right: 10px; } }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .contacts-list li {
        margin-right: 0;
        margin-left: auto; } }
    @media screen and (min-width: 1201px) and (max-width: 1470px) {
      .contacts-list li {
        margin-right: 20px; } }

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 10px;
  opacity: 1;
  transition-delay: 0.3s;
  transition: opacity 0.3s ease-in-out; }
  .footer.on-scroll {
    opacity: 0; }
  .footer.on-dark-screen * {
    color: #ffffff; }
  .footer--dark * {
    color: #ffffff; }
  .footer__inner {
    background-image: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  @media screen and (max-width: 991px) {
    .footer {
      position: absolute !important;
      padding: 15px 0 5px; }
      .footer * {
        color: #ffffff;
        font-size: 12px; } }

.main-menu {
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 8888;
  overflow: auto;
  background-image: linear-gradient(-31deg, #0f1011 0%, #323639 100%); }
  .main-menu a {
    font-family: "MuseoSansCyrl-100", sans-serif;
    font-size: 25px;
    color: #979797;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    position: relative;
    display: block; }
    .main-menu a:hover {
      color: #fff; }
      .main-menu a:hover:before {
        background-color: #8d836a; }
    .main-menu a:before {
      content: "";
      display: block;
      width: 9px;
      height: 9px;
      border: 1px solid #8d836a;
      border-radius: 50%;
      position: absolute;
      left: -33px;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.3s ease-in-out; }
    @media screen and (max-width: 991px) {
      .main-menu a {
        font-size: 16px; } }
  .main-menu ul {
    display: inline-block;
    margin: 0;
    padding: 0 0 0 33px; }
    .main-menu ul li {
      display: block;
      position: relative; }
      .main-menu ul li > ul {
        display: block;
        visibility: hidden;
        height: auto;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out; }
        .main-menu ul li > ul > li > a:before {
          border-radius: 0;
          height: 1px;
          background-color: #8d836a; }
        @media screen and (max-width: 991px) {
          .main-menu ul li > ul {
            visibility: visible;
            opacity: 1;
            max-height: 100vh; } }
      .main-menu ul li:hover > ul {
        visibility: visible;
        opacity: 1;
        max-height: 100vh; }

.onepage-pagination {
  right: auto;
  left: 12%;
  list-style: none;
  margin: 0;
  padding: 0; }
  .onepage-pagination:before, .onepage-pagination:after {
    content: "";
    display: block;
    width: 1px;
    height: 16px;
    background-color: #8d836a;
    margin: 0 auto; }
  .onepage-pagination li {
    margin: 0;
    padding: 0; }
    .onepage-pagination li a,
    .onepage-pagination li a.active {
      display: block;
      padding: 0;
      margin: 5px 0;
      padding: 0;
      width: 9px;
      height: 9px;
      border: 1px solid #8d836a;
      left: 0;
      background-color: transparent;
      border-radius: 50%; }
      .onepage-pagination li a:before,
      .onepage-pagination li a.active:before {
        display: none !important; }
    .onepage-pagination li a.active {
      background-color: #8d836a; }
  @media screen and (min-width: 0) and (max-width: 1440px) {
    .onepage-pagination {
      left: 3%; } }

.socials-list {
  list-style: none; }
  .socials-list li {
    display: inline-block;
    margin-right: 15px; }
    .socials-list li:last-child {
      margin-right: 0; }
    .socials-list li a {
      display: block;
      color: #1d2126;
      text-decoration: none;
      font-size: 16px; }

.first-screen-slider__slide {
  width: 100%; }
  @media screen and (max-width: 991px) {
    .first-screen-slider__slide {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%); } }
  .first-screen-slider__slide h2 {
    font-size: 60px; }
    @media screen and (max-width: 991px) {
      .first-screen-slider__slide h2 {
        font-size: 20px; } }
  .first-screen-slider__slide img {
    position: relative;
    left: -20%; }

@media screen and (max-width: 991px) {
  .first-screen-slider {
    height: 70vh; } }

.section-title {
  display: inline-block; }
  .section-title:after {
    content: "";
    display: block;
    height: 1px;
    background-color: #8d836a;
    margin-top: 6px; }
  .section-title h2 {
    font-size: 40px;
    margin: 0; }
    @media screen and (max-width: 991px) {
      .section-title h2 {
        font-size: 20px; } }
  .section-title-after small {
    font-family: "MuseoSansCyrl-300", sans-serif;
    font-size: 16px; }
  @media screen and (max-width: 991px) {
    .section-title {
      width: 100%;
      text-align: center;
      margin-bottom: 15px; } }

@media screen and (max-width: 1400px) {
  .screen {
    padding-top: 50px !important; } }

@media screen and (max-width: 1400px) {
  .special-pt {
    padding-top: 50px !important; } }

.why-we-teaser {
  flex-direction: column;
  justify-content: space-between; }
  .why-we-teaser__title h4 {
    font-size: 16px;
    margin: 0 0 5px 0;
    padding: 0;
    margin-bottom: 10px; }
    .why-we-teaser__title h4 span {
      display: block;
      padding-left: 15px; }
      .why-we-teaser__title h4 span:first-child {
        font-family: "MuseoSansCyrl-700", sans-serif;
        font-size: 16px;
        margin-right: 9px;
        position: relative; }
        .why-we-teaser__title h4 span:first-child:before {
          content: "";
          display: block;
          position: absolute;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: #8d836a;
          top: 7px;
          left: 0; }
      .why-we-teaser__title h4 span:last-child {
        padding-left: 15px;
        letter-spacing: 1.6px;
        font-size: 14px; }
  @media screen and (max-width: 991px) {
    .why-we-teaser__title {
      margin-top: 30px; } }
  .why-we-teaser__photo {
    width: 100%;
    padding-top: 137%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    margin-top: auto; }
    .why-we-teaser__photo .col-photo {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      transition: all 0.3s ease-in-out; }
      .why-we-teaser__photo .col-photo:hover {
        opacity: 1; }
    @media screen and (max-width: 991px) {
      .why-we-teaser__photo {
        padding-top: 150%; } }
    @media screen and (max-width: 767px) {
      .why-we-teaser__photo {
        padding-top: 56%; } }

.services-slider {
  padding: 50px 0 0 0;
  position: relative; }
  .services-slider__slide {
    padding: 0 15px; }
    .services-slider__slide img {
      display: block;
      z-index: 200;
      box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.2); }
    .services-slider__slide .front-image-wraper {
      display: inline-block;
      position: relative;
      max-width: 90%; }
      .services-slider__slide .front-image-wraper:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: -8px;
        top: 8px;
        background-color: #8d836a;
        z-index: 150;
        box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.2); }
    .services-slider__slide .back-image {
      z-index: 100;
      margin-top: -50%;
      max-width: 90%; }
    .services-slider__slide h4 {
      font-family: "MuseoSansCyrl-700", sans-serif;
      font-size: 30px;
      margin-bottom: 15px; }
      @media screen and (max-width: 991px) {
        .services-slider__slide h4 {
          display: none; } }
    .services-slider__slide blockquote {
      font-family: "MuseoSansCyrl-500", sans-serif;
      font-size: 16px;
      color: #1d2126;
      border-left: 1px solid #8d836a;
      padding-left: 15px; }
      @media screen and (max-width: 991px) {
        .services-slider__slide blockquote {
          padding: 0;
          margin-top: 10px;
          border-left: none;
          font-family: "MuseoSansCyrl-100", sans-serif;
          font-size: 12px; } }
  @media screen and (max-width: 992px) {
    .services-slider {
      padding-top: 0; } }

.detail-btn {
  display: inline-block;
  color: currentColor;
  text-decoration: none;
  position: relative;
  font-family: "MuseoSansCyrl-500", sans-serif;
  font-size: 16px;
  color: #1d2126;
  padding-right: 24px; }
  .detail-btn:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 24px;
    background-image: url(../img/arr-right.svg);
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    right: 0;
    transition: all 0.3s ease-in-out; }
  .detail-btn:hover {
    text-decoration: none;
    color: currentColor; }
    .detail-btn:hover:after {
      right: -10px;
      transform: rotateX(180deg); }

.services-arr,
.team-arr {
  display: block;
  width: 31px;
  height: 31px;
  border: 1px solid #8d836a;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 0;
  transition: all 0.3s ease-in-out; }
  .services-arr:before,
  .team-arr:before {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #8d836a;
    border-left: none;
    border-bottom: none;
    left: 50%;
    top: 50%;
    transform: translate(-35%, -50%) rotate(-135deg);
    transition: all 0.3s ease-in-out; }
  .services-arr-next,
  .team-arr-next {
    left: 60px; }
    .services-arr-next:before,
    .team-arr-next:before {
      transform: translate(-65%, -50%) rotate(45deg); }
    @media screen and (max-width: 991px) {
      .services-arr-next,
      .team-arr-next {
        left: auto;
        right: 0; } }
  .services-arr:hover,
  .team-arr:hover {
    background-color: #8d836a; }
    .services-arr:hover:before,
    .team-arr:hover:before {
      border-color: #fff; }

@media screen and (max-width: 992px) {
  .services-arr {
    top: 50%;
    transform: translate(0, 50%);
    z-index: 7777; }
    .services-arr-next {
      right: -14px; }
    .services-arr-prev {
      left: -14px; } }

.product-arr,
.partner-arr {
  display: block;
  width: 31px;
  height: 31px;
  border: 1px solid #8d836a;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  z-index: 500;
  transition: all 0.3s ease-in-out; }
  .product-arr:before,
  .partner-arr:before {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #8d836a;
    border-left: none;
    border-bottom: none;
    left: 50%;
    top: 50%;
    transform: translate(-35%, -50%) rotate(-135deg);
    transition: all 0.3s ease-in-out; }
  .product-arr-prev,
  .partner-arr-prev {
    left: -15px; }
  .product-arr-next,
  .partner-arr-next {
    right: -15px; }
    .product-arr-next:before,
    .partner-arr-next:before {
      transform: translate(-65%, -50%) rotate(45deg); }
  .product-arr:hover,
  .partner-arr:hover {
    background-color: #8d836a; }
    .product-arr:hover:before,
    .partner-arr:hover:before {
      border-color: #fff; }

.take-service-btn {
  display: inline-block;
  padding: 8px;
  width: 100%;
  max-width: 216px;
  color: #ffffff;
  background-color: #8d836a;
  box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.43);
  text-decoration: none;
  font-family: "MuseoSansCyrl-500", sans-serif;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  text-align: center; }
  .take-service-btn:hover {
    text-decoration: none;
    color: #fff;
    transform: scale(1.1);
    box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.43); }
  .take-service-btn-transparent {
    padding: 4px;
    color: #1d2126;
    background-color: transparent;
    border: 2px solid #8d836a;
    box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.13); }
    .take-service-btn-transparent:hover {
      background-color: #8d836a; }
    @media screen and (max-width: 991px) {
      .take-service-btn-transparent {
        max-width: 300px; } }

.team-slider {
  padding: 50px 0 0 0;
  position: relative; }
  .team-slider__slide {
    padding: 0 15px 20px; }
    @media screen and (max-width: 480px) {
      .team-slider__slide {
        padding: 0 40px; } }
    .team-slider__slide .photo-wraper {
      display: block;
      width: 100%;
      padding-top: 185%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      transition: all .3s ease-in-out; }
      @media screen and (max-width: 480px) {
        .team-slider__slide .photo-wraper {
          padding-top: 135%;
          background-position: 50% 0; } }
      .team-slider__slide .photo-wraper:hover {
        transform: scale(1.1); }
        .team-slider__slide .photo-wraper:hover + .slide-caption {
          top: 20px; }
    .team-slider__slide:hover .slide-caption span {
      text-decoration: none; }
      .team-slider__slide:hover .slide-caption span.name {
        font-family: "MuseoSansCyrl-700", sans-serif; }
      .team-slider__slide:hover .slide-caption span.position {
        font-family: "MuseoSansCyrl-100", sans-serif; }
    .team-slider__slide .slide-caption {
      position: relative;
      top: 0;
      padding-left: 20px;
      transition: all 0.5s ease-in-out; }
      .team-slider__slide .slide-caption span {
        display: block;
        font-size: 16px;
        color: #ffffff; }
        .team-slider__slide .slide-caption span.name {
          font-family: "MuseoSansCyrl-100", sans-serif; }
        .team-slider__slide .slide-caption span.position {
          font-family: "MuseoSansCyrl-700", sans-serif; }
        @media screen and (max-width: 991px) {
          .team-slider__slide .slide-caption span {
            font-size: 12px; } }
      .team-slider__slide .slide-caption:before {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #8d836a;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%); }
  @media screen and (max-width: 991px) {
    .team-slider .team-arr {
      top: 50%;
      transform: translate(0, -50%);
      z-index: 7777; } }
  @media screen and (max-width: 991px) {
    .team-slider .team-arr-next {
      right: 0; } }
  @media screen and (max-width: 991px) {
    .team-slider .team-arr-prev {
      left: 0; } }
  @media screen and (max-width: 991px) {
    .team-slider {
      padding-top: 0;
      padding-left: 30px;
      padding-right: 30px; } }

.product-slider {
  padding: 50px 0 0 0;
  position: relative; }
  .product-slider__slide {
    display: inline-block; }
    .product-slider__slide .photo-wraper {
      justify-content: center;
      align-items: center; }
    .product-slider__slide img {
      display: block; }
    .product-slider__slide .slide-caption {
      position: relative;
      padding-left: 20px;
      transition: all 0.5s ease-in-out;
      text-decoration: none; }
      .product-slider__slide .slide-caption span {
        display: block;
        font-size: 16px;
        color: #1d2126;
        text-decoration: none; }
        .product-slider__slide .slide-caption span.name {
          font-family: "MuseoSansCyrl-100", sans-serif;
          text-decoration: none; }
          .product-slider__slide .slide-caption span.name:hover {
            text-decoration: none; }
        .product-slider__slide .slide-caption span.position {
          font-family: "MuseoSansCyrl-700", sans-serif;
          text-decoration: none; }
          .product-slider__slide .slide-caption span.position:hover {
            text-decoration: none; }
        @media screen and (max-width: 991px) {
          .product-slider__slide .slide-caption span {
            font-size: 12px; } }
        .product-slider__slide .slide-caption span:hover {
          text-decoration: none; }
      .product-slider__slide .slide-caption:hover {
        text-decoration: none; }
      .product-slider__slide .slide-caption:before {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #8d836a;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%); }

.product-wraper {
  display: block;
  border: 2px solid transparent;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  margin: 0 30px; }
  .product-wraper.no-gutter {
    margin-left: 0;
    margin-right: 0; }
  .product-wraper:hover {
    text-decoration: none;
    border-color: #8d836a; }

.partner-slider {
  padding: 50px 0 0 0;
  position: relative; }
  .partner-slider__slide {
    display: inline-block; }
    .partner-slider__slide img {
      display: block; }
  .partner-slider .partner-wraper {
    display: flex;
    border: 2px solid transparent;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    margin: 0 30px;
    justify-content: center;
    align-items: center; }
    .partner-slider .partner-wraper:hover {
      text-decoration: none;
      border-color: #8d836a; }
    @media screen and (max-width: 991px) {
      .partner-slider .partner-wraper {
        position: relative;
        top: -10px; } }

.slider-row {
  height: 50vh; }

.bg-circle {
  border: 1px solid #8d836a;
  border-radius: 50%;
  align-self: center; }
  .bg-circle-outer {
    height: 100%; }
  .bg-circle-inner {
    height: 70%; }

.insta-wraper {
  padding: 20px 0;
  border-top: 1px solid #8d836a;
  border-bottom: 1px solid #8d836a; }
  @media screen and (max-width: 991px) {
    .insta-wraper {
      border: none;
      display: flex;
      justify-content: space-around; }
      .insta-wraper .row {
        width: 50%; } }

.insta-item {
  display: inline-block;
  width: 217px;
  height: 217px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-left: -5px;
  transition: all 0.3s ease-in-out; }
  .insta-item:first-child {
    margin-left: 0; }
  .insta-item:last-child {
    margin-right: 0 !important; }
  .insta-item:hover {
    transition-delay: 0s !important;
    transform: scale(1.1); }
  @media screen and (max-width: 1500px) {
    .insta-item {
      width: 190px;
      height: 190px; } }
  @media screen and (max-width: 1440px) {
    .insta-item {
      width: 160px;
      height: 160px; } }
  @media screen and (max-width: 991px) {
    .insta-item {
      width: 100%;
      padding-top: 100%;
      height: auto;
      margin: 0 !important; } }

.footer-contacts-list {
  list-style: none;
  margin: 60px 0 0 0;
  padding: 0; }
  .footer-contacts-list li {
    margin-bottom: 25px;
    padding-left: 35px;
    background-repeat: no-repeat; }
    .footer-contacts-list li:last-child {
      margin-bottom: 0; }
    .footer-contacts-list li a {
      font-family: "MuseoSansCyrl-100", sans-serif;
      font-size: 16px; }
      .footer-contacts-list li a:hover {
        color: currentColor;
        text-decoration: none; }
  .footer-contacts-list__mail {
    background-image: url(../img/mail-icon.svg);
    background-size: 25px auto;
    background-position: 0 3px; }
  .footer-contacts-list__phone {
    background-image: url(../img/phone-icon.svg);
    background-size: 12px auto;
    background-position: 6px 0; }
  .footer-contacts-list__place {
    background-image: url(../img/marker-icon.svg);
    background-size: 17px auto;
    background-position: 4px 0; }
  @media screen and (max-width: 992px) {
    .footer-contacts-list {
      margin-top: 15px; }
      .footer-contacts-list li {
        margin-bottom: 5px; } }

.video-wraper {
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding-top: 25%;
  background-image: url(../img/aventin_207_1200.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative; }
  .video-wraper.clicked {
    background-image: none; }
    .video-wraper.clicked:after, .video-wraper.clicked:before {
      display: none; }
  .video-wraper:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100; }
  .video-wraper:before {
    content: '';
    display: block;
    background-color: #000;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url(../img/play-ico.png);
    background-repeat: no-repeat;
    background-position: 57% 50%;
    z-index: 200; }
  .video-wraper iframe,
  .video-wraper video {
    position: absolute;
    z-index: 400;
    display: block;
    width: 85%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    background-color: #000; }
    .video-wraper iframe.video-play,
    .video-wraper video.video-play {
      opacity: 1; }
    @media screen and (max-width: 991px) {
      .video-wraper iframe,
      .video-wraper video {
        width: 100%; } }
  @media screen and (max-width: 991px) {
    .video-wraper {
      padding-top: 40%; } }

#map {
  width: 100%;
  height: 540px; }
  #map * {
    color: #1d2126 !important; }
  @media screen and (max-width: 991px) {
    #map {
      height: 163px; } }

.team-list {
  flex-flow: row wrap;
  justify-content: flex-start; }
  .team-list .team-slider__slide {
    height: auto !important;
    flex-basis: 18%;
    margin: 0 15px 60px 0;
    padding: 0; }
    .team-list .team-slider__slide .photo-wraper {
      max-width: 100% !important;
      width: 100%;
      padding-top: 185%; }
    @media screen and (max-width: 480px) {
      .team-list .team-slider__slide {
        flex-basis: 40%; } }
  @media screen and (max-width: 767px) {
    .team-list {
      justify-content: center; } }

.service-photo-1 {
  width: 38%;
  max-width: 282px;
  padding-top: 70%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative; }
  .service-photo-1:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: -11px;
    top: 11px;
    z-index: 100;
    background-color: #8d836a;
    box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.2); }
  .service-photo-1 .front-side {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }

.service-photo-2 {
  width: 58%;
  max-width: 359px;
  padding-top: 70%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative; }
  .service-photo-2:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 11px;
    top: -11px;
    z-index: 100;
    background-color: #8d836a;
    box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.2); }
  .service-photo-2 .front-side {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    box-shadow: 0px 5px 21px 0px rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }

.service-desc-wraper {
  flex-flow: row wrap; }

.service-desc {
  width: 50%; }
  .service-desc h4 {
    font-family: "MuseoSansCyrl-700", sans-serif;
    font-size: 20px; }
    @media screen and (max-width: 480px) {
      .service-desc h4 {
        font-size: 18px; } }
  .service-desc p {
    font-size: 16px;
    margin-top: 1em; }
    @media screen and (max-width: 480px) {
      .service-desc p {
        width: 100%; } }
  @media screen and (max-width: 480px) {
    .service-desc {
      width: 100%; } }

#price {
  background-image: url(../img/price-bg.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }

.prices-item {
  position: relative; }
  .prices-item:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 11px;
    left: -11px;
    z-index: 100;
    background-color: #8d836a; }
  .prices-item__inner {
    position: relative;
    z-index: 200;
    background-color: #f3f3f3; }
    .prices-item__inner .info {
      width: 90%;
      max-width: 376px; }
      .prices-item__inner .info h5 {
        font-family: "MuseoSansCyrl-700", sans-serif;
        font-size: 16px;
        padding: 0 0 10px 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid #8d836a; }
      .prices-item__inner .info a {
        text-decoration: none;
        padding: 0 15px; }
        .prices-item__inner .info a:hover span {
          transition: all 0.3s ease-in-out;
          color: #8d836a; }
        .prices-item__inner .info a span:last-child {
          font-family: "MuseoSansCyrl-700", sans-serif; }

.form-wraper {
  position: relative; }

.f-search {
  display: block;
  width: 100%; }
  .f-search input {
    display: block;
    width: 100%;
    font-family: "MuseoSansCyrl-100", sans-serif;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #8d836a;
    padding-left: 24px;
    color: #1d2126; }
    .f-search input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #1d2126;
      opacity: 1; }
    .f-search input::-moz-placeholder {
      /* Firefox 19+ */
      color: #1d2126;
      opacity: 1; }
    .f-search input:-ms-input-placeholder {
      /* IE 10+ */
      color: #1d2126;
      opacity: 1; }
    .f-search input:-moz-placeholder {
      /* Firefox 18- */
      color: #1d2126;
      opacity: 1; }
  .f-search button {
    width: 16px;
    height: 16px;
    background-color: transparent;
    background-image: url(../img/search-icon.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    border: none;
    position: absolute;
    top: 5px;
    left: 0;
    padding: 0;
    margin: 0;
    cursor: pointer; }

.filter-wraper h6 {
  padding-left: 23px;
  font-family: "MuseoSansCyrl-700", sans-serif; }

.filter-wraper ul li a {
  display: block;
  padding-left: 23px;
  position: relative; }
  .filter-wraper ul li a:before {
    content: "";
    display: block;
    width: 11px;
    height: 1px;
    background-color: #8d836a;
    position: absolute;
    left: 0;
    top: 50%; }
  .filter-wraper ul li a:after {
    content: "";
    display: block;
    width: 1px;
    height: 11px;
    background-color: #8d836a;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(0, -50%); }
  .filter-wraper ul li a.active:after {
    display: none; }
  .filter-wraper ul li a:hover {
    color: #8d836a;
    text-decoration: underline; }

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999; }
  .popup .inner {
    overflow: auto;
    padding: 30px;
    max-height: 90vh;
    background-color: #fff;
    position: relative; }
    .popup .inner img {
      display: block;
      margin: 0 auto 15px; }
  .popup h2 {
    font-size: 40px;
    margin: 0; }
    @media screen and (max-width: 991px) {
      .popup h2 {
        font-size: 30px; } }
  .popup h4 {
    font-size: 20px; }
  .popup .close-popup {
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    position: absolute;
    top: 5px;
    right: 10px;
    font-family: "MuseoSansCyrl-600", sans-serif;
    font-size: 30px;
    line-height: 27px;
    color: #000;
    text-decoration: none;
    border: 2px solid #000;
    border-radius: 50%;
    transition: all .3s ease-in-out; }
    .popup .close-popup:hover {
      color: #fff;
      text-decoration: none;
      background-color: #000; }

.first-screen-row {
  position: relative; }
  .first-screen-row .square {
    display: block;
    width: calc(100% - 30px);
    min-height: 315px;
    background-color: #f2f2f2;
    border: 2px solid #7e746a;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s ease-in-out; }
    @media screen and (max-width: 992px) {
      .first-screen-row .square {
        min-height: 175px; } }

.z-index-up {
  z-index: 8888; }

.onepage-pagination {
  transform: translate(0, -50%); }

.team-slider.no-carousel {
  display: flex;
  flex-wrap: wrap; }
  .team-slider.no-carousel .team-slider__slide {
    flex-basis: 25%; }

.t-100 {
  top: 100px; }
  @media screen and (max-width: 991px) {
    .t-100 {
      top: 5px; } }

.l--50 {
  left: -50px; }

.tr-0 {
  top: 0;
  right: 0; }

@media screen and (min-width: 992px) {
  .header, .footer {
    background-color: transparent;
    background-image: none; } }
