// 100
@font-face {
  font-family: "MuseoSansCyrl-100";
  src: url("../fonts/MuseoSansCyrl-100.otf");
}
// 100 - italic
@font-face {
  font-family: "MuseoSansCyrl-100Italic";
  src: url("../fonts/MuseoSansCyrl-100Italic.otf");
}

// 300
@font-face {
  font-family: "MuseoSansCyrl-300";
  src: url("../fonts/MuseoSansCyrl-300.otf");
}
// 300 - italic
@font-face {
  font-family: "MuseoSansCyrl-300Italic";
  src: url("../fonts/MuseoSansCyrl-300Italic.otf");
}

// 500
@font-face {
  font-family: "MuseoSansCyrl-500";
  src: url("../fonts/MuseoSansCyrl-500.otf");
}
// 500 - italic
@font-face {
  font-family: "MuseoSansCyrl-500Italic";
  src: url("../fonts/MuseoSansCyrl-500Italic.otf");
}

// 700
@font-face {
  font-family: "MuseoSansCyrl-700";
  src: url("../fonts/MuseoSansCyrl-700.otf");
}
// 700 - italic
@font-face {
  font-family: "MuseoSansCyrl-700Italic";
  src: url("../fonts/MuseoSansCyrl-700Italic.otf");
}

// 900
@font-face {
  font-family: "MuseoSansCyrl-900";
  src: url("../fonts/MuseoSansCyrl-900.otf");
}
// 900 - italic
@font-face {
  font-family: "MuseoSansCyrl-900Italic";
  src: url("../fonts/MuseoSansCyrl-900Italic.otf");
}

// Icon fonts
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?4snck8");
  src: url("../fonts/icomoon.eot?4snck8#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?4snck8") format("truetype"),
    url("../fonts/icomoon.woff?4snck8") format("woff"),
    url("../fonts/icomoon.svg?4snck8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

// Lighthaus
@font-face {
  font-family: "Lighthaus";
  src: url("../fonts/Lighthaus.otf");
}
